import { useState, useEffect } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";

import { isMobile } from "react-device-detect";

import { useHttp } from "hooks";

import { removeQueryParam } from "utils/common";

export const useApp = (
  isAuthenticated,
  logout,
  notif,
  setIsOpenModalTutorial
) => {
  const {
    // loading,
    request,
    error,
    clearError,
  } = useHttp();

  const [isOpenModalResetPass, setIsOpenModalResetPass] = useState(false);
  const [isOpenModalLogoutResetPass, setIsOpenModalLogoutResetPass] =
    useState(false);
  const [authChecked, setAuthChecked] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [resetPassParams, setResetPassParams] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const isLoginPage = location.pathname === "/login";

  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    if (isMobile) {
      // Лише перше завантаження
      setVh();

      // Під час кожного resize
      // window.addEventListener("resize", setVh);
      // return () => {
      //   window.removeEventListener("resize", setVh);
      // };
    }

    setAuthChecked(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isLoginPage && isMobile) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
    // eslint-disable-next-line
  }, [isLoginPage]);

  useEffect(() => {
    if (error) {
      console.log(error);
    }
    clearError();
    // eslint-disable-next-line
  }, [error, clearError]);

  useEffect(() => {
    const resetParams = searchParams.get("resetPass");
    if (authChecked && resetParams) {
      setResetPassParams(resetParams);
      if (isAuthenticated) {
        setIsOpenModalLogoutResetPass(true);
      } else {
        setIsOpenModalResetPass(true);
      }
    }
    // eslint-disable-next-line
  }, [searchParams, authChecked]);

  const closeModalResetPass = () => {
    setIsOpenModalResetPass(false);
  };

  const closeModalTutorial = () => {
    setIsOpenModalTutorial(false);
  };

  const closeModalLogoutResetPass = () => {
    setIsOpenModalLogoutResetPass(false);
  };

  const removeParamResetPass = () => {
    removeQueryParam("resetPass", searchParams, setSearchParams);
  };

  const resetPass = async (password) => {
    try {
      const { message } = await request("/api/auth/resetPassword", "POST", {
        newPass: password,
        resetKey: searchParams.get("resetPass"),
      });
      notif(message, "alert");
    } catch (e) {
      notif(e.message, "error");
    }
  };

  const handleLogoutAndResetPass = () => {
    logout();
    const newUrl = `?resetPass=${resetPassParams}`;
    navigate(newUrl, { replace: true });
  };

  return {
    isOpenModalResetPass,
    isOpenModalLogoutResetPass,
    closeModalResetPass,
    closeModalTutorial,
    closeModalLogoutResetPass,
    removeParamResetPass,
    resetPass,
    handleLogoutAndResetPass,
    isLoginPage,
  };
};
