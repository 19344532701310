import { useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
} from "@mui/material";

import { BodyModalForm, Toggle } from "components/Elements";

import { useTranslate } from "hooks/useTranslate.hook";

const ModalForm = ({ onSubmit, open, closeModal, title }) => {
  const [eventDate, setEventDate] = useState("");
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [address, setAddress] = useState("");
  const [isLiveQueue, setIsLiveQueue] = useState(false);

  const { t } = useTranslate();

  const handleClose = () => {
    closeModal();
  };

  const handleSwitchLiveQueue = (e) => {
    setIsLiveQueue(e.target.checked);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!eventDate || !name) {
      setValidationErrors({
        eventDate: !eventDate,
        name: !name,
      });
      return;
    }

    setValidationErrors({});

    onSubmit({ eventDate, name, message, address, isLiveQueue });

    handleClose();

    setEventDate("");
    setName("");
    setMessage("");
    setAddress("");
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit,
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <BodyModalForm
          eventDate={eventDate}
          setEventDate={setEventDate}
          message={message}
          setMessage={setMessage}
          name={name}
          setName={setName}
          validationErrors={validationErrors}
          address={address}
          setAddress={setAddress}
        />
        <Box mt={1}>
          <Toggle
            label={t("ENABLE_LIVE_QUEUE")}
            checked={isLiveQueue}
            handleSwitch={handleSwitchLiveQueue}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="modalButton">
          {t("CANCEL")}
        </Button>
        <Button type="submit" color="modalButton">
          {t("CREATE")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalForm;
